import getUsers from '../api/getUsers';
import getUserStatusCatalog from '../api/getUserStatusCatalog';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { getDocumentTypes } from '../../../api/documentTypes';

const userStatusLabels = {
    active: 'Activo',
    blocked: 'Bloqueado',
    pending: 'Pendiente'
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function useUsers() {
    const [users, setUsers] = useState([]);
    const [userStatusCatalog, setUserStatusCatalog] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectionMode, setSelectionMode] = useState(null);
    const [selectedUser, setSelectedUser] = useState({});
    const [userId, setUserId] = useState('');

    const queryUsers = async () => {
        const result = await getUsers(setErrorMessage);

        if (result) {
            setUsers(result);
            setIsLoading(false);
            return;
        }

        setIsLoading(false);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    const queryUserStatusCatalog = async () => {
        const result = await getUserStatusCatalog(setErrorMessage);

        if (result) {
            setUserStatusCatalog(result);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de estado de usuario. Inténtelo de nuevo.');
    };

    const queryDocumentTypes = async () => {
        const result = await getDocumentTypes(setErrorMessage);

        if (result) {
            setDocumentTypes(result);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de tipos de documento. Inténtelo de nuevo.');
    };

    useEffect(() => {
        queryUsers();
        queryUserStatusCatalog();
        queryDocumentTypes();
    }, []);

    const _handleClick = (event, selectedItem) => {
        event.preventDefault();
        setSelectedUser(selectedItem);
    };

    const _closeUserView = () => {
        setSelectedUser({});
        setSelectionMode(null);
    };

    const onSuccessUserUpdate = () => {
        _closeUserView();
        queryUsers();
    };

    const usersTable = useMemo(() => {
        return users.map((user) => {
            const fullName = `${user.firstName} ${user.lastName}`;
            return {
                col1: fullName,
                col2: user.email,
                col3: new Date(user.creation).toLocaleDateString(),
                col4: (
                    <span
                        className={classNames(
                            'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                            userStatusLabels[user.userStatus] === 'Activo' ? 'bg-green-100' : '',
                            userStatusLabels[user.userStatus] === 'Bloqueado' ? 'bg-red-100' : '',
                            userStatusLabels[user.userStatus] === 'Pendiente' ? 'bg-yellow-100' : '',
                            userStatusLabels[user.userStatus] === 'Activo' ? 'text-green-800' : '',
                            userStatusLabels[user.userStatus] === 'Bloqueado' ? 'text-red-800' : '',
                            userStatusLabels[user.userStatus] === 'Pendiente' ? 'text-yellow-800' : ''
                        )}
                    >
                        {userStatusLabels[user.userStatus]}
                    </span>
                ),
                col5: (
                    <div className="flex gap-2">
                        <div
                            onClick={(e) => _handleClick(e, user)}
                            className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                        >
                            Editar
                            <span className="sr-only">, {fullName}</span>
                        </div>
                        <div onClick={() => setUserId(user.id)} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer">
                            Borrar
                            <span className="sr-only"></span>
                        </div>
                    </div>
                )
            };
        });
    }, [users]);

    const userColumns = useMemo(() => {
        return [
            {
                Header: 'Nombre',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Correo electrónico',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Fecha de creación',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Estado',
                accessor: 'col4' // accessor is the "key" in the data
            },
            {
                Header: 'Acciones',
                accessor: 'col5' // accessor is the "key" in the data
            }
        ];
    }, []);

    const userTableInstance = useTable(
        { columns: userColumns, data: usersTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return {
        users,
        errorMessage,
        queryUsers,
        usersTable,
        isLoading,
        setIsLoading,
        userColumns,
        userTableInstance,
        selectionMode,
        setSelectionMode,
        _closeUserView,
        selectedUser,
        userId,
        setUserId,
        userStatusCatalog,
        documentTypes,
        onSuccessUserUpdate
    };
}
