import { useEffect, useMemo, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FormButton from '../../components/formElements/FormButton';
import { SubscriptionApi } from '../../services/Subscription';

const AddSubscriptionModal = ({ dialog, setDialog, getSubscriptionList }) => {

    const [formData, setFormData] = useState({
        title: "",
        id: "",
        description: "",
        price: ""
    })


    const closeModal = () => {
        setDialog({ ...dialog, isOpen: false, data: {} });
    };


    const handleSubmit = async () => {
        try {
            if (!dialog?.data?.id) {
                delete formData?.id
            }
            formData.price = parseInt(formData.price);
            let res = await dialog.id ? await SubscriptionApi.edit(formData) : await SubscriptionApi.add(formData)
            getSubscriptionList()

            console.log("resssss", res)
        } catch (error) {
            console.log(error)
        } finally {
            closeModal()
        }
    }


    useEffect(() => {
        if (dialog?.isOpen) {
            setFormData({ ...formData, title: dialog?.data?.title, id: dialog?.data?.id, description: dialog?.data?.description, price: dialog?.data?.price })
        }
    }, [dialog])

    const handleValueInput = (e) => {
        console.log(e, 'value');
        const key = e.keyCode;
        // Only allow numbers to be entered
        if ((key < 48 || key > 57) && (key < 96 || key > 105) && key !== 8) {
            e.preventDefault();
        }
    }

    return (
        <>
            <Transition appear show={dialog.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        {dialog.id ? 'Editar suscripción' : 'Agregar suscripción'}

                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className='mb-4 items-center grid gap-4 grid-cols-3'>
                                            <label>
                                                Nombre  :
                                            </label>
                                            <input className='col-span-2' type="text" value={formData?.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                        </div>

                                        <div className='mb-4 items-center grid gap-4 grid-cols-3'>
                                            <label>
                                                Descripción  :
                                            </label>
                                            <textarea className='col-span-2' type="text" value={formData?.description} maxLength={100} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                                        </div>

                                        <div className='mb-4 items-center grid gap-4 grid-cols-3'>
                                            <label>
                                                Valor  :
                                            </label>
                                            <input className='col-span-2' type="text" onKeyDown={(e) => handleValueInput(e)} value={formData?.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
                                        </div>

                                        <div className="mt-4">
                                            <FormButton label={!dialog.id ? 'Agregar' : 'Actualizar'} type="button" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default AddSubscriptionModal