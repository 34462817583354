import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useDoctorProfiles from './hooks/useDoctorProfiles';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import DoctorOwnProfile from './components/doctorOwnProfile/DoctorOwnProfile';
import { useEffect } from 'react';
import { useState } from 'react';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';
import deleteDoctor from './api/deleteDoctor';

const DoctorProfiles = ({ userInfo }) => {
  const { userTableInstance, isLoading, selectedUser, _closeUserView, queryUsers, userId, setUserId, onSuccessUserUpdate, isDeletedFilter,handleIsDeletedFilter } = useDoctorProfiles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


  useEffect(() => {
    if (userId) {
      setOpenDeleteModal(true);
    }
  }, [userId])

  const _onConfirmDelete = async (id) => {
    await deleteDoctor(id);
    setOpenDeleteModal(false);
    setUserId('');
    onSuccessUserUpdate();
  };

  const onClose = () => {
    setOpenDeleteModal(false);
    setUserId('');
  }
  useEffect(() => {
      if (userId) {
        setOpenDeleteModal(true);
      }
    }, [userId])
  

  return (
    <WhiteCardContainer>
      {JSON.stringify(selectedUser) === '{}' && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Perfiles de Doctores" />
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && <SimpleDataTable tableInstance={userTableInstance} handleIsDeletedFilter={handleIsDeletedFilter}
        isDeletedFilter={isDeletedFilter} />}

      {!isLoading && JSON.stringify(selectedUser) !== '{}' && <DoctorOwnProfile selectedUser={selectedUser} onClose={_closeUserView} userInfo={userInfo} queryUsers={queryUsers} />} 
   
      {openDeleteModal && (
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => onClose()}
          onCancel={() => onClose()}
          onConfirm={() => _onConfirmDelete(userId)}
          title={'¿Quieres eliminar a la doctores?'}
          bodyText={'Esta acción es permanente.'}
        />
      )}
    </WhiteCardContainer>
    
  );

};

export default DoctorProfiles;
