import React, { useEffect, useMemo, useState } from 'react'
import { FormFieldsContainer, HalfRow, WhiteCardContainer } from '../../components'
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import FormButton from '../../components/formElements/FormButton';
import { SubscriptionApi } from '../../services/Subscription';
import AddSubscriptionModal from './AddSubscriptionModal';
import Loader from '../../components/Loader/Loader';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';


const Subscription = () => {

    const [list, setList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [dialog, setDialog] = useState({ data: {}, isOpen: false, id: '' });


    const getSubscriptionList = async () => {
        try {
            setIsLoading(true)
            let res = await SubscriptionApi.getList()
            setList(res.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getSubscriptionList()
    }, [refresh]);

    const _onConfirmDelete = (id) => {
        SubscriptionApi.delete(id).then(() => setRefresh(!refresh));
        setOpenDeleteModal(false);
    };


    const subscriptionColumns = useMemo(() => {
        return [
            { Header: 'Nombre', accessor: 'col1' },
            { Header: 'Descripción', accessor: 'col2' },
            { Header: 'Valor', accessor: 'col3' },
            { Header: 'Fecha de creación', accessor: 'col4' },
            { Header: 'Acciones', accessor: 'col5' }
        ];
    }, []);

    let subscriptionTable = useMemo(() => {
        return list.map((item) => {
            return {
                col1: item?.title,
                col2: item?.description,
                col3: '$'+(item?.price ? item.price : ''),
                col4: new Date(item?.creation).toLocaleDateString(),
                col5: (
                    <div className="flex gap-2">
                        <div onClick={() => setDialog({ data: item, isOpen: true, id: item?.id })} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer" >
                            Editar
                            <span className="sr-only" ></span>
                        </div >
                        <div onClick={() => setOpenDeleteModal(item.id)} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer">
                            Borrar
                            <span className="sr-only"></span>
                        </div>
                    </div >
                )
            };
        });
    }, [list]);

    const tableInstance = useTable({ columns: subscriptionColumns, data: subscriptionTable }, useFilters, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        data,
        columns,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        setPageSize
    } = tableInstance;

    const [value, setValue] = useState(state.globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);


    if (isLoading) {
        return <Loader />;
    }

    return (
        <WhiteCardContainer>
            <div className="px-4 sm:px-6 lg:px-8 mb-10">
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="grid grid-cols-6 gap-6 my-4">
                                <HalfRow>
                                    <label htmlFor={'search'} className="block text-sm font-medium text-dl-accent">
                                        Buscador
                                    </label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <MagnifyingGlassIcon className="h-5 w-5" />
                                        </div>
                                        <input
                                            type="text"
                                            name={'search'}
                                            id={'search'}
                                            className={`mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm pl-10`}
                                            placeholder={`Escriba para buscar en registros`}
                                            value={value || ''}
                                            onChange={(e) => {
                                                setValue(e.target.value);
                                                onChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                </HalfRow>
                                <HalfRow>
                                    <FormFieldsContainer>
                                        {/* <ProfileFormHeader title="City" /> */}
                                        <div className="flex-row-reverse">
                                            <FormButton type="button" label="Añadir Suscripción" onClick={() => setDialog({ data: {}, isOpen: true, id: "" })} />
                                        </div>
                                    </FormFieldsContainer>
                                </HalfRow>
                            </div>
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        <div className="inline-flex items-center">
                                                            {column.render('Header')}
                                                            <span>{column.isSorted ? column.isSortedDesc ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" /> : ''}</span>
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white" {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td {...cell.getCellProps()} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                                    <div className="hidden sm:block">
                                        {!columns.some((col) => col.isSorted) && !state.globalFilter && page[0] ? (
                                            <p className="text-sm text-gray-700">
                                                Mostrando desde <span className="font-medium">{page[0].index + 1}</span> hasta <span className="font-medium">{page[page.length - 1].index + 1}</span> de un total de{' '}
                                                <span className="font-medium">{data.length}</span> resultados
                                            </p>
                                        ) : (
                                            <p className="text-sm text-gray-700">
                                                Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageCount}</span>
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex flex-1 justify-between sm:justify-end">
                                        <select
                                            id="displayOptions"
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            className="mr-4 relative inline-flex items-center rounded-md mx-1 w-32 border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            <option value="10">Mostrar 10</option>
                                            <option value="20">Mostrar 20</option>
                                            <option value="50">Mostrar 50</option>
                                        </select>
                                        <button
                                            type="button"
                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            Anterior
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            Siguiente
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddSubscriptionModal dialog={dialog} setDialog={setDialog} getSubscriptionList={getSubscriptionList} />


            {openDeleteModal && (
                <DeleteConfirmationModal
                    open={Boolean(openDeleteModal)}
                    onClose={() => setOpenDeleteModal(false)}
                    onCancel={() => setOpenDeleteModal(false)}
                    onConfirm={() => _onConfirmDelete(openDeleteModal)}
                    title={'¿Quieres eliminar la suscripción?'}
                    bodyText={'Esta acción es permanente.'}
                />
            )}
        </WhiteCardContainer>
    )
}

export default Subscription