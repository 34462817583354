import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import AvailabilityList from './components/availabilityList/AvailabilityList';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../components/formElements/HalfRow';
import useDoctorAvailability from './hooks/useDoctorAvailability';
import FormButton from '../../../../../components/formElements/FormButton';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../../components/formElements/FormResetButton';
import FormDatePicker from '../../../../../components/formElements/FormDatePicker';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { useCallback, useMemo, useRef } from 'react';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { SimpleDataTable } from './components/SimpleDataTable';

const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

export default function DoctorAvailability(props) {
    const { availabilities, queryAllDoctorAvailabilities, schedulesAsOptions, schedules, selectedUser } = props;

    const {
        register,
        errors,
        control,
        watch,
        handleSubmit,
        saveAvailability,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedAvailability,
        resetState,
        isDirty
    } = useDoctorAvailability(queryAllDoctorAvailabilities, selectedUser);

    const appTimeRef = useRef('');
    const scheduleRef = useRef('');

    appTimeRef.current = watch('appTime', '');
    scheduleRef.current = watch('idSchedule', '');

    const _validateAppTime = () => {
        const findSchedule = schedules.find((item) => item.id === parseInt(scheduleRef.current));

        if (!findSchedule) {
            return false;
        }

        const openTime = new Date();
        openTime.setHours(findSchedule.openHours1.substr(0, findSchedule.openHours1.indexOf(':')));
        openTime.setMinutes(findSchedule.openHours1.substr(findSchedule.openHours1.indexOf(':') + 1));
        openTime.setSeconds(0);

        const closeTime = new Date();
        closeTime.setHours(findSchedule.openHours2.substr(0, findSchedule.openHours2.indexOf(':')));
        closeTime.setMinutes(findSchedule.openHours2.substr(findSchedule.openHours2.indexOf(':') + 1));
        closeTime.setSeconds(0);

        return appTimeRef.current > openTime && appTimeRef.current < closeTime;
    };

    const _findSchedule = useCallback(
        (idSchedule) => {
            return schedules.find((schedule) => schedule.id === parseInt(idSchedule));
        },
        [schedules]
    );

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    }

    const availabilityTable = useMemo(() => {
        return availabilities ? availabilities?.map((item) => {
            return {
                col1: _findSchedule(item.idSchedule).dayName,
                col2: typeof item.appTime === 'string'
                ? item.appTime.substr(0, 5)
                : format(item.appTime, 'HH:mm'),
                col3: format(parseISO(item.creation), 'yyyy-MM-dd H:mm'),
                col4: (
                    <span
                        className={classNames(
                            'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                            item.status ? 'bg-green-100' : 'bg-red-100',
                            item.status ? 'text-green-800' : 'text-red-800'
                        )}
                    >
                        {item.status ? 'Activo' : 'Inactivo'}
                    </span>
                )
            };
        }) : [];
    }, [availabilities]);

    const availabilityColumns = useMemo(() => {
        return [
            {
                Header: 'Día',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Hora de cita',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Creación',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Estado',
                accessor: 'col4' // accessor is the "key" in the data
            },
        ];
    }, []);

    const availabilityTableInstance = useTable(
        { columns: availabilityColumns, data: availabilityTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return (
        <>
            {/* Add Availability Form */}
            {/* <form action="#" method="POST" onSubmit={handleSubmit(saveAvailability)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={
                                !isEditing
                                    ? 'Agregar horario disponible para Citas'
                                    : 'Actualizar horario disponible para Citas'
                            }
                            initialText={`Ingresa horarios específicos en los que atiendes pacientes. 
                                Estos son los espacios de consulta, y determinan el intervalo en el que atiendes a tus pacientes.`}
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormSelectDrop
                                    label={'Horario'}
                                    identifier={'idSchedule'}
                                    options={schedulesAsOptions}
                                    defaultValue={defaultValues.idSchedule}
                                    helperText={'Requerido'}
                                    registration={register('idSchedule', { required: true })}
                                    errorMessage={errors.idSchedule ? 'Este campo es requerido' : null}
                                />
                            </HalfRow>
                            <HalfRow></HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="appTime"
                                    rules={{ required: true, validate: _validateAppTime }}
                                    render={({ field }) => {
                                        return (
                                            <FormDatePicker
                                                identifier="appTime"
                                                label="Seleccionar hora de cita"
                                                errorMessage={
                                                    errors.appTime
                                                        ? 'Este campo es requerido. Asegúrese que la hora este dentro del rango.'
                                                        : null
                                                }
                                                helperText={
                                                    'Requerido. Debe estar dentro del rango del horario seleccionado.'
                                                }
                                                iconComponent={
                                                    <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                }
                                                datePickerProps={{
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                    selected: field.value,
                                                    dateFormat: 'HH:mm:ss',
                                                    onChange: (date) => field.onChange(date),
                                                    showTimeSelect: true,
                                                    showTimeSelectOnly: true,
                                                    timeIntervals: 15,
                                                    timeCaption: 'Hora',
                                                    dropdownMode: 'select',
                                                    minTime: setHours(setMinutes(new Date(), 0), 6),
                                                    maxTime: setHours(setMinutes(new Date(), 0), 20)
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </HalfRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                            <div className="h-60"></div>
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form> */}
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Disponibilidades existentes'} />
                </div>
                {availabilities?.length === 0 ? (
                    <p className="ml-4 mt-4">No hay disponibilidades para mostrar</p>
                ) : (
                    // <AvailabilityList items={availabilities} schedules={schedules} onClick={setSelectedAvailability} />
                    <SimpleDataTable tableInstance={availabilityTableInstance} />
                )}
            </div>
        </>
    );
}
