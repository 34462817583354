import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useUsers from './hooks/useUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import UserDetails from './components/UserDetails/UserDetails';
import { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';
import deleteUser from './api/deleteUser';

const Users = () => {
  const { userTableInstance, isLoading, selectedUser, userId, setUserId, _closeUserView, userStatusCatalog, documentTypes, onSuccessUserUpdate } = useUsers();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  
  useEffect(() => {
    if (userId) {
      setOpenDeleteModal(true);
    }
  }, [userId])

  const _onConfirmDelete = async (id) => {
    // Speciality.delete(id).then(() => setRefresh(!refresh));
    await deleteUser(userId);
    setOpenDeleteModal(false);
    setUserId('');
    onSuccessUserUpdate();
    // setRefresh(!refresh);
  };

  const onClose = () => {
    setOpenDeleteModal(false);
    setUserId('');
  }

  return (
    <WhiteCardContainer>
      {JSON.stringify(selectedUser) === '{}' && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Pacientes" />
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && <SimpleDataTable tableInstance={userTableInstance} />}

      {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
        <UserDetails
          selectedUser={selectedUser}
          onCancel={_closeUserView}
          onSuccessfulSave={onSuccessUserUpdate}
          selectionMode={'edit'}
          userStatusCatalog={userStatusCatalog}
          documentTypes={documentTypes}
        />
      )}

      {openDeleteModal && (
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => onClose()}
          onCancel={() => onClose()}
          onConfirm={() => _onConfirmDelete(userId)}
          title={'¿Quieres eliminar a la paciente?'}
          bodyText={'Esta acción es permanente.'}
        />
      )}
    </WhiteCardContainer>
  );
};

export default Users;
